import { Box } from "@chakra-ui/react";

const Events = () => {
    return (
        <Box>
            Ciao
        </Box>
    );
}

export default Events;